var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VueHtml2pdf",
    {
      ref: "contract",
      attrs: {
        "show-layout": false,
        "float-layout": true,
        "enable-download": false,
        "preview-modal": false,
        "paginate-elements-by-height": 1400,
        filename: _vm.contractFileName,
        "pdf-quality": 2,
        "manual-pagination": true,
        "pdf-format": "a4",
        "pdf-orientation": "portrait",
        "pdf-content-width": "800px",
      },
      on: {
        beforeDownload: function ($event) {
          return _vm.beforeContractDownload($event)
        },
      },
    },
    [
      _c(
        "section",
        {
          staticClass: "p-5",
          staticStyle: { color: "#000" },
          attrs: { slot: "pdf-content" },
          slot: "pdf-content",
        },
        [
          _c("div", { staticClass: "html2pdf__page-break" }, [
            _c("h6", [
              _vm._v(
                "Besteller BVBA | Guido Gezellelaan 122 bus 1, 3550 Heusden-Zolder | BE0683.531.086 | tel 011/36.19.86"
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "mt-3 mb-3 pb-3 d-flex flex-row text-center border-bottom",
              },
              [
                _c("img", {
                  attrs: { src: "/img/menute.be/logo-icon.png", width: "120" },
                }),
                _c("h2", { staticClass: "ml-4 flex-fill align-self-center" }, [
                  _vm._v(_vm._s(_vm.appTitle)),
                  _c("br"),
                  _vm._v(
                    "(" +
                      _vm._s(_vm.$t("contract.registration_certificate")) +
                      ")"
                  ),
                ]),
              ]
            ),
            _c(
              "table",
              { staticClass: "table table-borderless table-contract" },
              [
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.restaurant_name"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.restaurant_name)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.restaurant_address"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(
                      ": " +
                        _vm._s(_vm.form.restaurant_address) +
                        " " +
                        _vm._s(_vm.form.restaurant_postcode) +
                        " " +
                        _vm._s(_vm.form.restaurant_city)
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.restaurant_tel"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.restaurant_tel)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.restaurant_gsm"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.restaurant_gsm)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.firma_naam"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.firma_naam)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.firma_mz_adres"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(
                      ": " +
                        _vm._s(_vm.form.firma_mz_adres) +
                        " " +
                        _vm._s(_vm.form.firma_mz_postcode) +
                        " " +
                        _vm._s(_vm.form.firma_mz_city)
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.website"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.website)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.firma_type"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.firma_type)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.btw_nummer"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.btw_nummer)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.eigenaar1_naam"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.eigenaar1_naam)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.eigenaar1_gsm"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.eigenaar1_gsm)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.eigenaar1_email"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.eigenaar1_email)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.eigenaar2_naam"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.eigenaar2_naam)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.eigenaar2_gsm"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.eigenaar2_gsm)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.eigenaar2_email"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.eigenaar2_email)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.bank_rekeningnummer"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.bank_rekeningnummer)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.bank_rekeninghouder"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.bank_rekeninghouder)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("contract.bank_naam"))),
                  ]),
                  _c("td", { staticClass: "col-8" }, [
                    _vm._v(": " + _vm._s(_vm.form.bank_naam)),
                  ]),
                ]),
              ]
            ),
            _c("p", {
              staticClass: "mb-3",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("contract.description1", {
                    appTitle: this.appTitle,
                    commission:
                      _vm.form.type_commission == "A"
                        ? "7"
                        : _vm.form.type_commission == "B"
                        ? "8"
                        : _vm.form.type_commission == "C"
                        ? "9"
                        : _vm.form.type_commission == "D"
                        ? "10"
                        : _vm.form.type_commission == "E"
                        ? "11"
                        : _vm.form.type_commission == "F"
                        ? "12"
                        : _vm.form.type_commission == "G"
                        ? "13"
                        : "14",
                  })
                ),
              },
            }),
            _c("p", [_vm._v(_vm._s(_vm.$t("contract.description2")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("contract.description3")))]),
            _c("table", { staticClass: "table table-borderless table-sign" }, [
              _c("tr", { staticClass: "first-row" }, [
                _c("td", { staticClass: "col-2" }, [
                  _vm._v(_vm._s(_vm.$t("contract.place"))),
                ]),
                _c("td", { staticClass: "col-4 fill" }, [
                  _vm._v(": " + _vm._s(_vm.formContract.place)),
                ]),
                _c("td", { staticClass: "col-2" }, [
                  _vm._v(_vm._s(_vm.$t("contract.name"))),
                ]),
                _c("td", { staticClass: "col-4 fill" }, [
                  _vm._v(": " + _vm._s(_vm.formContract.name)),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "col-2" }, [
                  _vm._v(_vm._s(_vm.$t("contract.signature"))),
                ]),
                _c("td", { staticClass: "col-4" }, [
                  _c("img", { attrs: { width: "90%", src: _vm.sign } }),
                ]),
                _c("td", { staticClass: "col-2" }, [
                  _vm._v(_vm._s(_vm.$t("contract.date"))),
                ]),
                _c("td", { staticClass: "col-4 fill" }, [
                  _vm._v(
                    ": " +
                      _vm._s(
                        new Date().toJSON().slice(0, 10).replace(/-/g, "/")
                      )
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", [
            _c("h6", [
              _vm._v(
                "Besteller BVBA | Guido Gezellelaan 122 bus 1, 3550 Heusden-Zolder | BE0683.531.086 | tel 011/36.19.86"
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "mt-3 mb-3 pb-3 d-flex flex-row text-center border-bottom",
              },
              [
                _c("img", {
                  attrs: { src: "/img/menute.be/logo-icon.png", width: "120" },
                }),
                _c("h2", { staticClass: "ml-4 flex-fill align-self-center" }, [
                  _vm._v(_vm._s(_vm.appTitle) + " "),
                  _c("br"),
                  _vm._v(_vm._s(_vm.$t("contract.technical_information"))),
                ]),
              ]
            ),
            _c("h5", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(_vm.$t("contract.delivery_zones")) + ":"),
            ]),
            _c("table", { staticClass: "table table-sm table-zip-codes" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("contract.region"))),
                  ]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("contract.min_amount"))),
                  ]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("contract.delivery_charge"))),
                  ]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("contract.free_from"))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.form.zip_codes, function (zip_code, index) {
                  return _c("tr", { key: zip_code.id }, [
                    _c("td", { staticClass: "col-1" }, [
                      _vm._v(_vm._s(index + 1)),
                    ]),
                    _c("td", { staticClass: "col-5" }, [
                      _vm._v(
                        _vm._s(zip_code.zcZip) + " " + _vm._s(zip_code.zcCity)
                      ),
                    ]),
                    _c("td", { staticClass: "col-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(zip_code.pivot.minimum_order)
                        )
                      ),
                    ]),
                    _c("td", { staticClass: "col-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(zip_code.pivot.delivery_charge)
                        )
                      ),
                    ]),
                    _c("td", { staticClass: "col-2" }, [
                      _vm._v(
                        _vm._s(_vm._f("toCurrency")(zip_code.pivot.free_above))
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
            _c("h5", { staticClass: "mt-4 mb-2" }, [
              _vm._v(_vm._s(_vm.$t("contract.opening_closing_hours")) + ":"),
            ]),
            _c("table", { staticClass: "table table-sm table-opening-hours" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "col-1" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("contract.monday")))]),
                  ]),
                  _c("td", { staticClass: "col-5" }, [
                    _vm._v(": " + _vm._s(_vm.form.open_monday)),
                  ]),
                  _c("td", { staticClass: "col-1" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("contract.friday")))]),
                  ]),
                  _c("td", { staticClass: "col-5" }, [
                    _vm._v(": " + _vm._s(_vm.form.open_friday)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-1" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("contract.tuesday")))]),
                  ]),
                  _c("td", { staticClass: "col-5" }, [
                    _vm._v(": " + _vm._s(_vm.form.open_tuesday)),
                  ]),
                  _c("td", { staticClass: "col-1" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("contract.saturday")))]),
                  ]),
                  _c("td", { staticClass: "col-5" }, [
                    _vm._v(": " + _vm._s(_vm.form.open_saturday)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-1" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("contract.wednesday"))),
                    ]),
                  ]),
                  _c("td", { staticClass: "col-5" }, [
                    _vm._v(": " + _vm._s(_vm.form.open_wednesday)),
                  ]),
                  _c("td", { staticClass: "col-1" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("contract.sunday")))]),
                  ]),
                  _c("td", { staticClass: "col-5" }, [
                    _vm._v(": " + _vm._s(_vm.form.open_sunday)),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "col-1" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("contract.thursday")))]),
                  ]),
                  _c("td", { staticClass: "col-5", attrs: { colspan: "3" } }, [
                    _vm._v(": " + _vm._s(_vm.form.open_thursday)),
                  ]),
                ]),
              ]),
            ]),
            _c("h5", { staticClass: "mt-4 mb-2" }, [
              _vm._v(_vm._s(_vm.$t("contract.delivery_hours")) + ":"),
            ]),
            _c(
              "table",
              { staticClass: "table table-sm table-delivery-hours" },
              [
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "col-1" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.$t("contract.monday")))]),
                    ]),
                    _c("td", { staticClass: "col-5" }, [
                      _vm._v(": " + _vm._s(_vm.form.deliver_monday)),
                    ]),
                    _c("td", { staticClass: "col-1" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.$t("contract.friday")))]),
                    ]),
                    _c("td", { staticClass: "col-5" }, [
                      _vm._v(": " + _vm._s(_vm.form.deliver_friday)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "col-1" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("contract.tuesday"))),
                      ]),
                    ]),
                    _c("td", { staticClass: "col-5" }, [
                      _vm._v(": " + _vm._s(_vm.form.deliver_tuesday)),
                    ]),
                    _c("td", { staticClass: "col-1" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("contract.saturday"))),
                      ]),
                    ]),
                    _c("td", { staticClass: "col-5" }, [
                      _vm._v(": " + _vm._s(_vm.form.deliver_saturday)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "col-1" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("contract.wednesday"))),
                      ]),
                    ]),
                    _c("td", { staticClass: "col-5" }, [
                      _vm._v(": " + _vm._s(_vm.form.deliver_wednesday)),
                    ]),
                    _c("td", { staticClass: "col-1" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.$t("contract.sunday")))]),
                    ]),
                    _c("td", { staticClass: "col-5" }, [
                      _vm._v(": " + _vm._s(_vm.form.deliver_sunday)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "col-1" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("contract.thursday"))),
                      ]),
                    ]),
                    _c(
                      "td",
                      { staticClass: "col-5", attrs: { colspan: "3" } },
                      [_vm._v(": " + _vm._s(_vm.form.deliver_thursday))]
                    ),
                  ]),
                ]),
              ]
            ),
            _c("h5", { staticClass: "mt-4 mb-2" }, [
              _vm._v(_vm._s(_vm.$t("contract.restaurant_types")) + ":"),
            ]),
            _c(
              "ul",
              { staticClass: "nav restaurant-types" },
              _vm._l(_vm.form.restaurant_types, function (restaurant_type) {
                return _c("li", { key: restaurant_type.id }, [
                  _vm._v(" " + _vm._s(restaurant_type.type) + " "),
                ])
              }),
              0
            ),
            _c("h5", { staticClass: "mt-4 mb-2" }, [
              _vm._v(_vm._s(_vm.$t("contract.payment_method")) + ":"),
            ]),
            _c(
              "h6",
              { staticClass: "mt-4 mb-2" },
              [
                _vm._v(
                  _vm._s(_vm.$t("contract.connection_method")) +
                    ": " +
                    _vm._s(
                      {
                        box: "MagicBox",
                        soft: "Etenonline Software",
                        mail: "E-Mail",
                      }[_vm.form.connectiewijze]
                    ) +
                    " "
                ),
                _vm.form.connectiewijze == "box"
                  ? [
                      _vm._v(
                        " ( " +
                          _vm._s(
                            { rent: "Huur", buy: "Aankoop" }[
                              _vm.form.boxpaymenttype
                            ]
                          ) +
                          " "
                      ),
                      _vm.form.boxpaymenttype == "rent"
                        ? [
                            _vm._v(
                              " , " + _vm._s(_vm.form.boxrentstartdate) + " "
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" ) "),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("h5", { staticClass: "mt-4 mb-2" }, [
              _vm._v(_vm._s(_vm.$t("contract.comments")) + ":"),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }